import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import DayJS from "dayjs";

import { Link } from "gatsby";

// Utils
import { getItemSlug, StatNames } from "../utils/item";

// Components
import Img from "gatsby-image";

const Container = styled.div`
  h1 {
    font-size: 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 12px;
    margin-bottom: 12px;
  }

  h2 {
    font-size: 20px;
  }
`;

const ItemColumn = styled.div`
  h1 {
    padding-bottom: 10px;
    margin-bottom: 24px;
    border-bottom: 1px solid #ccc;
  }
`;

const ItemInfo = styled.ul`
  margin: 0 0 24px;
  padding: 0;

  li {
    font-size: 16px;
    line-height: 18px;
    font-family: sans-serif;
    margin: 0 0 6px;
    padding-bottom: 6px;
    text-align: left;
    text-transform: capitalize;

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    em {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #333;
      font-style: normal;
      text-transform: none;

      &.positive {
        color: green;
      }

      &.negative {
        color: red;
      }

      img {
        margin-left: 6px;
        width: 30px;
        height: 30px;

        &:first-of-type {
          margin-left: 12px;
        }
      }
    }

    border-bottom: 1px solid #ccc;

    &:first-child {
      font-weight: bold;
    }
  }
`;

const Rarity = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${p => p.rarity};
  position: absolute;
  top: 12px;
  left: 12px;
  border: 1px solid #333;
  box-shadow: 4px 4px #000;
  z-index: 1000;
`;

const RarityInline = styled.div`
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: ${p => p.rarity};
  border: 1px solid #333;
  box-shadow: 2px 2px #000;
  margin-right: 8px;
  transform: translateY(-2px);
`;

const EnhancementTable = styled.div.attrs({
  className: "card-body p-0",
})`
  overflow-x: scroll;
  padding-right: 15px;

  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const ItemClasses = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;

  li {
    flex: 0 0 calc(25% - (15px / 4));
    margin-right: 5px;
    margin-bottom: 5px;
    position: relative;
    padding: 4px;
    background: #ccc;

    &:nth-child(4n) {
      margin-right: 0;
    }

    p {
      font-size: 12px;
      margin: 0 0 3px;
      text-align: center;
    }

    img {
      width: 100% !important;
      height: auto !important;
    }
  }
`;

const rarity = {
  red: "Abyssal",
  orange: "Mystical",
  yellow: "Epic",
  purple: "Unique",
  blue: "Rare",
  green: "Magic",
  white: "Normal",
  grey: "Old",
};

export default class itemPage extends React.Component {
  constructor(props) {
    super(props);

    const item = props.pageContext.item;

    let maxEnhance = 35;
    if (item.ap && item.ap.length > 0) {
      maxEnhance = item.ap.length;
    } else if (item.dp && item.dp.length > 0) {
      maxEnhance = item.dp.length;
    }

    this.state = {
      enhanceLevel: 0,
      submissionFormVisible: false,
      copied: false,
      maxEnhance,
    };
  }

  enhanceIncrease() {
    const { enhanceLevel, maxEnhance } = this.state;

    let newEnhanceLevel = enhanceLevel + 1;

    if (newEnhanceLevel > maxEnhance) {
      newEnhanceLevel = 0;
    }

    this.setState({
      enhanceLevel: newEnhanceLevel,
    });
  }

  enhanceDecrease() {
    const { enhanceLevel, maxEnhance } = this.state;

    let newEnhanceLevel = enhanceLevel - 1;

    if (newEnhanceLevel < 0) {
      newEnhanceLevel = maxEnhance;
    }

    this.setState({
      enhanceLevel: newEnhanceLevel,
    });
  }

  render() {
    const { enhanceLevel, maxEnhance } = this.state;
    const { pageContext } = this.props;
    const { item } = pageContext;

    const isEnhanced = enhanceLevel >= 1;

    let appendix = item.type;

    if (item.type === "main") {
      appendix = `Main Weapon`;
    } else if (item.type === "sub") {
      appendix = `Sub-Weapon`;
    }

    const itemName = `${item.name}`;

    let descriptionType = appendix;
    if (descriptionType.substring(descriptionType.length - 1) === "s") {
      descriptionType = descriptionType.substring(
        0,
        descriptionType.length - 1,
      );
    }

    const slug = getItemSlug(item.type, item.name);

    const pageUrl = `https://bdm.wiki${slug}`;
    const metaDescription = `${itemName} - ${
      rarity[item.rarity]
    } ${appendix} - Base AP ${item.baseAp}, Base DP: ${
      item.baseDp
    } - Black Desert Mobile MMO`;

    let ogImage = "https://bdm.wiki/BDMwiki-logo.jpg";
    if (item.images && item.images.length > 0) {
      ogImage = item.images[0].file.url;
    }

    return (
      <>
        <Helmet
          title={`BDM.wiki | ${itemName} ${appendix} -  ${
            rarity[item.rarity]
          } | Black Desert Mobile MMO`}
          description={metaDescription}
        >
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover"
          />

          <meta name="description" content={metaDescription} />

          <meta
            property="og:title"
            content={`BDM.wiki | ${itemName} | Item Wiki`}
          />
          <meta property="og:description" content={metaDescription} />
          <meta property="og:url" content={pageUrl} />
          <meta property="og:image" content={`https:${ogImage}`} />
          <meta property="og:type" content="”website”" />
        </Helmet>

        <Container className="container mt-3 mb-5">
          <div className="row">
            <div className="col">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`/`}>Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item"
                    style={{ textTransform: "capitalize" }}
                  >
                    <Link to={`/items/${item.type}`}>{appendix}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {itemName}
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-4">
              <div className="card mb-4 overflow-hidden">
                <div className="position-relative">
                  {item.images && (
                    <div
                      id="carouselExampleControls"
                      className="carousel slide"
                      data-ride="carousel"
                    >
                      <div className="carousel-inner">
                        {item.images.map((image, i) => {
                          return (
                            <div
                              className={`carousel-item${
                                i === 0 ? " active" : ""
                              }`}
                              key={i}
                            >
                              <Img
                                fluid={image.fluid}
                                className="d-block w-100"
                                alt={image.description}
                                loading="lazy"
                              />
                            </div>
                          );
                        })}
                      </div>
                      {item.images.length > 1 && (
                        <>
                          <a
                            className="carousel-control-prev"
                            href="#carouselExampleControls"
                            role="button"
                            data-slide="prev"
                          >
                            <span
                              className="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Previous</span>
                          </a>
                          <a
                            className="carousel-control-next"
                            href="#carouselExampleControls"
                            role="button"
                            data-slide="next"
                          >
                            <span
                              className="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Next</span>
                          </a>
                        </>
                      )}
                    </div>
                  )}

                  {!item.images && (
                    <>
                      <Rarity rarity={item.rarity} />
                      <img
                        src="/bdm-wiki_placeholder.jpg"
                        alt="Placeholder Image"
                        className="card-img-top"
                      />
                    </>
                  )}
                </div>
                <div className="card-body">
                  {item.classes && item.classes.length === 8 ? (
                    <p className="m-0">
                      This item can be equipped and used by all classes:{" "}
                      {item.classes.map(itemClass => itemClass.name).join(", ")}
                    </p>
                  ) : (
                    <p className="m-0">
                      This item can be equipped by the follow classes:{" "}
                      {item.classes.map(itemClass => itemClass.name).join(", ")}
                    </p>
                  )}
                </div>
                {false && (
                  <div className="card-body">
                    {item.classes && (
                      <>
                        <p>All classes that can equip:</p>
                        <ItemClasses>
                          {item.classes.map((itemClass, i) => {
                            return (
                              <li key={i}>
                                <p>{itemClass.name}</p>
                                <Img
                                  fluid={itemClass.icon.fluid}
                                  loading="lazy"
                                />
                              </li>
                            );
                          })}
                        </ItemClasses>
                      </>
                    )}
                  </div>
                )}
                <div className="card-footer text-muted d-flex justify-content-between">
                  Last updated:{" "}
                  <span>{DayJS(item.updatedAt).format("hh:MMA MM/DD/YY")}</span>
                </div>
              </div>
            </div>

            <ItemColumn className="col-12 col-md-8">
              <div className="card">
                <h3 className="card-header h5">Database Information</h3>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <ItemInfo>
                        <li>{itemName}</li>
                        <li>
                          Rarity{" "}
                          <em>
                            <RarityInline rarity={item.rarity} />
                            <span className="sr-only">{item.rarity}</span>
                            {rarity[item.rarity]}
                          </em>
                        </li>
                        <li>
                          Max Enhance <em>{maxEnhance}</em>
                        </li>
                        <li>
                          AP
                          <em>
                            {isEnhanced && item.ap.length > 0
                              ? `${item.ap[enhanceLevel - 1].content} (${
                                  item.baseAp
                                }+${item.ap[enhanceLevel - 1].content -
                                  item.baseAp})`
                              : item.baseAp}
                          </em>
                        </li>
                        <li>
                          DP
                          <em>
                            {isEnhanced && item.dp.length > 0
                              ? `${item.dp[enhanceLevel - 1].content} (${
                                  item.baseDp
                                }+${item.dp[enhanceLevel - 1].content -
                                  item.baseDp})`
                              : item.baseDp}
                          </em>
                        </li>
                      </ItemInfo>

                      {item.ap.length > 0 || item.dp.length > 0 ? (
                        <div
                          className="btn-group w-100 mb-4"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.enhanceDecrease.bind(this)}
                          >{`-`}</button>
                          <div className="btn btn-disabled">
                            Preview Enhance Level {enhanceLevel}
                          </div>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.enhanceIncrease.bind(this)}
                          >{`+`}</button>
                        </div>
                      ) : (
                        <p>
                          We do not have enhancement data for this item yet,
                          please check again later.
                        </p>
                      )}
                    </div>

                    <div className="col-12 col-lg-6">
                      <ItemInfo>
                        <li>&nbsp;</li>
                        <li>
                          Crystal Sockets <em>{item.sockets.join(" or ")}</em>
                        </li>
                        {item.weight && (
                          <li>
                            Item Weight <em>{item.weight} LT</em>
                          </li>
                        )}
                      </ItemInfo>
                      {item.subStats && (
                        <ItemInfo>
                          <li>Sub Stats</li>
                          {item.subStats.map((sub, i) => {
                            return (
                              <li key={i}>
                                {StatNames[sub.name]}
                                <em
                                  className={
                                    sub.amount > 0 ? "positive" : "negative"
                                  }
                                >
                                  {sub.amount > 0 ? "+" : ""}
                                  {sub.amount}
                                  {sub.type === "percent" ? "%" : ""}
                                </em>
                              </li>
                            );
                          })}
                        </ItemInfo>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mt-4 mb-4">
                <h3 className="card-header h5">Enhancement Table</h3>
                <EnhancementTable>
                  <table className="table m-0">
                    <thead>
                      <tr>
                        <th>Level</th>
                        {Array.from(Array(maxEnhance)).map((level, i) => (
                          <th key={i}>+{i + 1}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {item.ap.length > 0 && (
                        <tr>
                          <th>AP</th>
                          {item.ap.map((level, i) => (
                            <td key={i}>{level.content}</td>
                          ))}
                        </tr>
                      )}
                      {item.dp.length > 0 && (
                        <tr>
                          <th>DP</th>
                          {item.dp.map((level, i) => (
                            <td key={i}>{level.content}</td>
                          ))}
                        </tr>
                      )}
                    </tbody>
                  </table>
                </EnhancementTable>
              </div>
            </ItemColumn>
          </div>
        </Container>
      </>
    );
  }
}
